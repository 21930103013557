<template>
  <div style="width: 100%">
    <div class="mbl_header">
      <div class="mbl_logo">
        <img class="mbl_img" src="@/assets/logo.gif" alt="logo" />
        <div class="mbl_title-body">
          <div class="mbl_title">四川省执业药师继续教育培训</div>
          <div class="mbl_sub">
            SI CHUAN SHENG ZHI YE YAO SHI JI XU JIAO YU PEI XUN
          </div>
        </div>
      </div>
    </div>
    <div class="header-m-user-box" v-if="$route.meta.showHeader">
      <div class="header-m-user-box-mini">
        <div class="header-m-user-box-name">Hi,褚瑞堂</div>
        <div class="header-m-user-box-maxim">
          人类被赋予了一种工作，那就是精神的成长
        </div>
      </div>
      <div class="header-m-user-box-avatar">
        <img src="@/assets/avatar.png" alt="avatar" />
      </div>
    </div>
    <div class="m-body" :style="$route.meta.padding ? {} : { padding: '0px' }">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <!--      <div v-if="showTabbar" style="height: 50px">-->
      <!--        <div class="m_tabbar">-->
      <!--          <div-->
      <!--            class="m_tabbar_item"-->
      <!--            @click="keepAliveNavigation('/specialized')"-->
      <!--          >-->
      <!--            <div class="m_tabbar_item_icon">-->
      <!--              <svg-icon icon="icon-rongqi-3" />-->
      <!--            </div>-->
      <!--            <div class="m_tabbar_item_text">专业课程</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="m_tabbar_item"-->
      <!--            @click="keepAliveNavigation('/publicDemand')"-->
      <!--          >-->
      <!--            <div class="m_tabbar_item_icon">-->
      <!--              <svg-icon icon="icon-rongqi1" />-->
      <!--            </div>-->
      <!--            <div class="m_tabbar_item_text">公需课程</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="m_tabbar_item"-->
      <!--            @click="keepAliveNavigation('/onlineExam')"-->
      <!--          >-->
      <!--            <div class="m_tabbar_item_icon">-->
      <!--              <svg-icon icon="icon-a-zhuanyekaoshi1x" />-->
      <!--            </div>-->
      <!--            <div class="m_tabbar_item_text">专业考试</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="m_tabbar_item"-->
      <!--            @click="keepAliveNavigation('/openOnlineExam')"-->
      <!--          >-->
      <!--            <div class="m_tabbar_item_icon">-->
      <!--              <svg-icon icon="icon-a-gongxukaoshi1x" />-->
      <!--            </div>-->
      <!--            <div class="m_tabbar_item_text">公需考试</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="m_tabbar_item"-->
      <!--            @click="keepAliveNavigation('/electronicInvoice')"-->
      <!--          >-->
      <!--            <div class="m_tabbar_item_icon">-->
      <!--              <svg-icon icon="icon-a-rongqi1x" />-->
      <!--            </div>-->
      <!--            <div class="m_tabbar_item_text">电子发票</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-if="showTabbar">
        <van-tabbar
          border
          route
          active-color="#B50413"
          :placeholder="true"
          inactive-color="#818496"
        >
          <van-tabbar-item replace to="/specialized">
            <template slot="icon">
              <svg-icon icon="icon-rongqi-3" />
            </template>
            专业课程
          </van-tabbar-item>
          <van-tabbar-item replace to="/publicDemand">
            <template slot="icon">
              <svg-icon icon="icon-rongqi1" />
            </template>
            公需课程
          </van-tabbar-item>
          <van-tabbar-item replace to="/onlineExam" icon="search">
            <template slot="icon">
              <svg-icon icon="icon-a-zhuanyekaoshi1x" />
            </template>
            专业考试
          </van-tabbar-item>
          <van-tabbar-item replace to="/openOnlineExam" icon="search">
            <template slot="icon">
              <svg-icon icon="icon-a-gongxukaoshi1x" />
            </template>
            公需考试
          </van-tabbar-item>
          <van-tabbar-item replace to="/electronicInvoice" icon="search">
            <template slot="icon">
              <svg-icon icon="icon-a-rongqi1x" />
            </template>
            电子发票
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </div>
    <van-action-sheet v-model="show">
      <div class="pop-weixin-content">
        <van-icon class="weixinIcon" name="wechat" />
        <div class="weixinText">推荐使用微信打开本站</div>
        <div class="weixinButton">
          <a href="weixin://">
            <van-button type="primary">复制到微信打开</van-button>
          </a>
          <a href="javascript:void(0);" @click="closeWeiXinPop">
            <van-button style="margin-top: 1rem" type="default"
              >继续浏览本页面</van-button
            >
          </a>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import Vue from "vue";
import svgIcon from "@/components/svgIcon.vue";
import SvgIcon from "@/components/svgIcon.vue";
export default {
  name: "MobileLayout",
  components: { SvgIcon },
  computed: {
    svgIcon() {
      return svgIcon;
    },
  },
  data() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // window.alert("手机 WeiXin");
      Vue.prototype.WeiXin = false;
    } else {
      // window.alert("手机 noWeiXin");
      Vue.prototype.WeiXin = true; //用于提示微信打开
    }
    return {
      // show: this.WeiXin,
      show: false,
      showTabbar: false,
    };
  },
  activated() {
    console.log("1");
  },
  created() {
    this.showTabbar = this.$route.meta.showTabbar;
  },
  watch: {
    $route() {
      this.showTabbar = this.$route.meta.showTabbar;
    },
  },
  mounted() {
    var viewport = document.querySelector("meta[name=viewport]");
    viewport.content =
      "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no";
    var htmlDoc = document.getElementsByTagName("html")[0];
    htmlDoc.style.width = "100%";
    htmlDoc.style.minWidth = "100%";
    document.body.style.width = "100%";
    document.body.style.minWidth = "100%";
    document.body.style.minHeight = "100vh";
    document.body.style.background = "#f5f5f5";
  },
  methods: {
    closeWeiXinPop() {
      this.show = false;
      this.WeiXin = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.m-body {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}

.header-m-user-box {
  width: 100%;
  padding: 15px 15px 25px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #7b3e3e;
  color: #fff;

  .header-m-user-box-mini {
    .header-m-user-box-name {
      color: #fff;
      font-size: 20px;
    }

    .header-m-user-box-maxim {
      font-size: 14px;
      color: #fff;
      margin-top: 5px;
    }
  }

  .header-m-user-box-avatar {
    width: 48px;
    height: 48px;
    border-radius: 48px;

    img {
      display: block;
      width: 100%;
      border-radius: 48px;
    }
  }
}

.mbl_header {
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(180deg, #920705 0%, #740705 100%);

  .mbl_logo {
    display: flex;
    align-items: center;

    .mbl_img {
      width: 3rem;
      height: 3rem;
      margin: 0 0.5rem;
    }

    .mbl_title-body {
      color: #ffffff;

      .mbl_title {
        font-size: 1.3rem;
      }

      .mbl_sub {
        margin-top: 2px;
        font-size: 0.7rem;
      }
    }
  }
}

html,
body {
  width: 100%;
  min-width: 100%;
}

.pop-weixin-content {
  padding: 2rem;
  text-align: center;

  .weixinIcon {
    color: #07c160;
    font-size: 6rem;
    margin-bottom: 1rem;
  }

  .weixinText {
    font-size: 1rem;
    color: #21252e;
    margin-bottom: 1rem;
  }

  .weixinButton {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  }
}
.m_tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 50px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  .m_tabbar_item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: rgb(129, 132, 150);
    font-size: 12px;
    line-height: 1;
    cursor: pointer;
    flex-direction: column;
    .m_tabbar_item_icon {
      font-size: 22px;
      position: relative;
      margin-bottom: 4px;
    }
  }
}
</style>
