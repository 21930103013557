import store from "@/store";
if (
  /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|HarmonyOS|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  document.body.style.minWidth = "100%";
  store.commit("SET_CURRENT_DEVICE", 0);
} else if (window.innerWidth < 600) {
  document.body.style.minWidth = "100%";
  store.commit("SET_CURRENT_DEVICE", 0);
} else {
  store.commit("SET_CURRENT_DEVICE", 1);
}
