import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import PcLayout from "@/layout/pcLayout.vue";
import MobileLayout from "@/layout/mobileLayout.vue";
Vue.use(VueRouter);
const isPC = store.state.currentDevice;
const routes = [
  {
    path: "/",
    component: isPC ? PcLayout : MobileLayout,
    redirect: "authentication",
    children: [
      {
        // 鉴权页面
        path: "/authentication",
        name: "authentication",
        component: (resolve) => {
          require(["@/views/authentication.vue"], resolve);
        },
        meta: {
          noAuth: true,
        },
      },
      {
        // 考试页面
        path: "/examination",
        name: "examination",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/examination.vue"], resolve)
            : require(["@/views/mobile/m_examination.vue"], resolve);
        },
        meta: {},
      },
      {
        // 公需课图文
        path: "/imageAndText",
        name: "imageAndText",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/imageAndText.vue"], resolve)
            : require(["@/views/mobile/m_imageAndText.vue"], resolve);
        },
        meta: {
          padding: true,
        },
      },
      {
        // 专业课视频播放
        path: "/majorPlayerPage",
        name: "majorPlayerPage",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/majorPlayerPage.vue"], resolve)
            : require(["@/views/mobile/m_majorPlayerPage.vue"], resolve);
        },
        meta: {},
      },
      {
        // 专业课考试
        path: "/onlineExam",
        name: "onlineExam",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/onlineExam.vue"], resolve)
            : require(["@/views/mobile/m_onlineExam.vue"], resolve);
        },
        meta: {
          showTabbar: true,
          showHeader: true,
          padding: true,
          keepAlive: true,
        },
      },
      {
        // 专业课考试
        path: "/openOnlineExam",
        name: "openOnlineExam",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/openOnlineExam.vue"], resolve)
            : require(["@/views/mobile/m_openOnlineExam.vue"], resolve);
        },
        meta: {
          showTabbar: true,
          showHeader: true,
          padding: true,
          keepAlive: true,
        },
      },
      {
        // 公需课视频播放
        path: "/openPlayer",
        name: "openPlayer",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/openPlayer.vue"], resolve)
            : require(["@/views/mobile/m_openPlayer.vue"], resolve);
        },
        meta: {},
      },
      {
        // 公需课课程
        path: "/publicDemand",
        name: "publicDemand",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/publicDemand.vue"], resolve)
            : require(["@/views/mobile/m_publicDemand.vue"], resolve);
        },
        meta: {
          showTabbar: true,
          showHeader: true,
          padding: true,
          keepAlive: true,
        },
      },
      {
        // 报名页面
        path: "/signUp",
        name: "signUp",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/signUp.vue"], resolve)
            : require(["@/views/mobile/m_signUp.vue"], resolve);
        },
        meta: {
          keepAlive: true,
        },
      },
      {
        // 专业课课程
        path: "/specialized",
        name: "specialized",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/specialized.vue"], resolve)
            : require(["@/views/mobile/m_specialized.vue"], resolve);
        },
        meta: {
          showTabbar: true,
          showHeader: true,
          padding: true,
          keepAlive: true,
        },
      },
      {
        // 电子发票
        path: "/electronicInvoice",
        name: "electronicInvoice",
        component: (resolve) => {
          isPC
            ? require(["@/views/pc/electronicInvoice.vue"], resolve)
            : require(["@/views/mobile/m_electronicInvoice.vue"], resolve);
        },
        meta: {
          showTabbar: true,
          showHeader: true,
          padding: true,
        },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.VUE_BASE_URL,
  routes: routes,
});
export default router;
