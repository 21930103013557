export default {
  methods: {
    keepAliveNavigation(path, query = {}, noRefresh = true, _blank = false) {
      if (!_blank) {
        this.$route.meta.noisRefresh = noRefresh;
        this.$router.push({ path, query });
      } else {
        const { href } = this.$router.resolve({ path, query });
        window.open(href, "_blank");
      }
    },
    inspectRefresh(callBack) {
      if (!this.$route.meta.noisRefresh) {
        if (typeof callBack === "function") {
          callBack();
        }
      }
      this.$route.meta.noisRefresh = false;
    },
    handlerBack() {
      if (history.length > 1) {
        this.$router.go(-1);
      } else {
        window.close();
      }
    },
    //
  },
};
