export const setStore = (name, content, maxAge = null) => {
  if (!window || !name) {
    return;
  }
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  const storage = window.localStorage;
  storage.setItem(name, content);
  if (maxAge && !isNaN(parseInt(maxAge))) {
    const timeout = parseInt(new Date().getTime() / 1000);
    storage.setItem(`${name}_expire`, timeout + maxAge);
  }
};
export const getStore = (name) => {
  if (!global.window || !name) {
    return;
  }

  const content = window.localStorage.getItem(name);
  const _expire = window.localStorage.getItem(`${name}_expire`);

  if (_expire) {
    const now = parseInt(new Date().getTime() / 1000);
    if (now > _expire) {
      return;
    }
  }

  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
};
export const clearStore = (name) => {
  if (!global.window || !name) {
    return;
  }

  window.localStorage.removeItem(name);
  window.localStorage.removeItem(`${name}_expire`);
};
export const clearAll = () => {
  // if (!global.window) {
  //   return
  // }
  window.localStorage.clear();
};
