<template>
  <svg
    class="svg-icon"
    :class="className"
    @click="handleClick"
    aria-hidden="true"
  >
    <use :xlink:href="`#${icon}`"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: String,
    className: String,
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
<style scoped lang="scss">
.svg-icon {
  cursor: pointer;
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
</style>
