import store from "@/store";
import { MessageBox, Message, Loading } from "element-ui";
import { Dialog, Toast } from "vant";
let elLoading = null;

export function getUrlParams(url) {
  let params = { hash: {}, search: {} };
  let hashIndex = url.indexOf("#");
  let searchIndex = url.indexOf("?");
  let hashStr = "";
  let searchStr = "";
  if (hashIndex > -1) {
    hashStr = url.substring(hashIndex + 1)?.split("?")[1] || "";
    url = url.substring(0, hashIndex);
  }
  if (searchIndex > -1) {
    searchStr = url.substring(searchIndex + 1);
    url = url.substring(0, searchIndex);
  }
  let hashParams = hashStr.split("&").filter((item) => !!item);
  let searchParams = searchStr.split("&").filter((item) => !!item);
  for (let i = 0; i < hashParams.length; i++) {
    let item = hashParams[i].split("=");
    params.hash[item[0]] = item[1];
  }
  for (let i = 0; i < searchParams.length; i++) {
    let item = searchParams[i].split("=");
    params.search[item[0]] = item[1];
  }
  return params;
}
export function showModel(options) {
  if (store.state.currentDevice) {
    MessageBox.confirm(options.content, options.title, {
      confirmButtonText: options.confirmButtonText || "确定",
      cancelButtonText: options.cancelButtonText || "取消",
      showConfirmButton: options.showConfirmButton || true,
      showCancelButton: options.showCancelButton || false,
      showClose: options.showClose,
      closeOnClickModal: options.closeModal || false,
      closeOnPressEscape: options.closeEsc || false,
      lockScroll: true,
      type: options.type || "warning",
      callback: (action) => {
        if (action === "confirm") {
          if (typeof options.confirm === "function") {
            options.confirm();
          }
        }
        if (action === "cancel") {
          if (typeof options.cancel === "function") {
            options.cancel();
          }
        }
        if (action === "close") {
          if (typeof options.close === "function") {
            options.close();
          }
        }
      },
    });
  } else {
    Dialog({
      title: options.title,
      message: options.content,
      confirmButtonText: options.confirmButtonText || "确定",
      cancelButtonText: options.cancelButtonText || "取消",
      showConfirmButton: options.showConfirmButton || true,
      showCancelButton: options.showCancelButton || false,
      // overlay: options.closeModal || false,
      closeOnClickOverlay: options.closeModal || false,
      lockScroll: true,
    })
      .then(() => {
        if (typeof options.confirm === "function") {
          options.confirm();
        }
      })
      .catch(() => {
        if (typeof options.cancel === "function") {
          options.cancel();
        }
      });
  }
}

export function showToast(options) {
  if (store.state.currentDevice) {
    Message({
      message: options.message,
      type: options.type,
      duration: options.duration || 0,
      showClose: !(options.duration && options.duration > 0),
    });
  } else {
    Toast({
      type: options.type === "error" ? "fail" : options.type,
      message: options.message,
      duration: options.duration || 3000,
    });
  }
}

export function showLoading(content) {
  if (store.state.loadNumber <= 0) {
    if (store.state.currentDevice) {
      elLoading = Loading.service({
        text: content,
        background: "rgba(0, 0, 0, 0.8)",
      });
    } else {
      if (document.getElementById("loading-model")) {
        document.body.removeChild(document.getElementById("loading-model"));
      }
      const loadingDiv = document.createElement("div");
      loadingDiv.id = "loading-model";
      const loadingBox = document.createElement("div");
      loadingBox.className = "loading-model-box";
      const loadingSpring = document.createElement("div");
      loadingSpring.className = "loading-spring";
      const loadingTip = document.createElement("span");
      loadingTip.className = "loading-tip";
      loadingTip.textContent = content;
      loadingBox.appendChild(loadingSpring);
      loadingBox.appendChild(loadingTip);
      loadingDiv.appendChild(loadingBox);
      document.body.appendChild(loadingDiv);
    }
  } else {
    store.commit("SET_LOAD_NUMBER", store.state.loadNumber++);
  }
}

export function hideLoading() {
  if (store.state.loadNumber >= 1) {
    store.commit("SET_LOAD_NUMBER", store.state.loadNumber--);
  } else {
    if (store.state.currentDevice) {
      elLoading && elLoading.close();
    } else {
      if (document.getElementById("loading-model")) {
        document.body.removeChild(document.getElementById("loading-model"));
      }
    }
  }
}

//校验统一社会信用代码
export function isValidOrgXyCode(Code) {
  try {
    Code = Code.toUpperCase()
    if (Code == 'Q9943010500405590G') {
      return true
    }
    //18位校验及大写校验
    if ((Code.length != 18)) {
      return false
    } else {
      var Ancode //统一社会信用代码的每一个值
      var Ancodevalue //统一社会信用代码每一个值的权重
      var total = 0
      var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]
      var str = '0123456789ABCDEFGHJKLMNPQRTUWXY'
      //不用I、O、S、V、Z
      for (var i = 0; i < Code.length - 1; i++) {
        Ancode = Code.substr(i, 1)
        Ancodevalue = str.indexOf(Ancode)
        total = total + Ancodevalue * weightedfactors[i]
        //权重与加权因子相乘之和
      }
      var logiccheckcode = 31 - total % 31
      if (logiccheckcode == 31) {
        logiccheckcode = 0
      }
      var Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y'
      var _Yzlogiccheckcode = Str.split(',')[logiccheckcode]
      var checkcode = Code.substr(17, 1)
      if (_Yzlogiccheckcode != checkcode) {
        return false
      } else {
      }
      return true
    }
  } catch (e) {
    return false
  }
}
