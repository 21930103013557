import store from "@/store";
import { showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";
export const beforeEach = (to, form, next) => {
  if (store.state.currentDevice == 1) {
    window.scrollTo(0, 0);
  }
  if (to.meta.noAuth) {
    next();
  } else {
    if (store.state.token) {
      next();
    } else {
      next();
      // 未登录
      // showModel({
      //   title: "温馨提示",
      //   content: "登录失效！请重新登录",
      //   showClose: false,
      //   confirmButtonText: "立即登录",
      //   confirm: () => {
      //     // 返回登录页面
      //     storage.clearAll();
      //     window.location.replace("https://www.sclpa.cn/Default.aspx#");
      //   },
      // });
    }
  }
};
