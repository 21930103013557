import Vue from "vue";
import App from "./App.vue";
import "./init";
import router from "./router/index";
import store from "./store";
import { beforeEach } from "@/router/beforeEach";
import Vant from "vant";
import "vant/lib/index.css";
import mixins from "@/utils/mixins";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import SlideVerify from "vue-monoplasty-slide-verify";
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(SlideVerify);
Vue.mixin(mixins);
router.beforeEach(beforeEach);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
